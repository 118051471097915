import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { loaderState, userFormState, userState } from "../atoms/atoms";
import { verifyEmail, verifySMS } from "../services/api/auth";
import { toast } from "react-toastify";
import VerificationForm from "../components/forms/VerificationForm";
import { VERIFICATION_MESSAGES } from "../constants/constants";
import PhoneIcon from "../assets/icons/PhoneIcon";
import EmailIcon from "../assets/icons/EmailIcon";

const VerifyAccount = () => {
  const [smsCode, setSmsCode] = useState("");
  const [emailCode, setEmailCode] = useState("");
  const [, setLoader] = useRecoilState(loaderState);
  const navigate = useNavigate();
  const [emailVerified, setEmailVerified] = useState(false);
  const user = useRecoilValue(userState);
  const userFormType = useRecoilValue(userFormState);

  const handleVerification = async (type, code) => {
    setLoader(true);
    try {
      const response =
        type === "email"
          ? await verifyEmail(user?.email, code)
          : await verifySMS(user?.email, code);
      debugger;
      if (response?.data?.status === "success") {
        toast.success(
          type === "email"
            ? VERIFICATION_MESSAGES.emailSuccess
            : VERIFICATION_MESSAGES.smsSuccess
        );
        if (type === "email") {
          if (userFormType === "EDDQ - Business") {
            navigate("/questionnaire");
          } else {
            setEmailVerified(true);
          }
        }
        if (type === "sms") navigate("/questionnaire");
      } else {
        toast.error(response?.response?.data?.message ?? response?.message);
      }
    } catch (error) {
      toast.error(error.message || VERIFICATION_MESSAGES.errorGeneric);
    } finally {
      setLoader(false);
    }
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    handleVerification("email", emailCode);
  };

  const handleSmsSubmit = (e) => {
    e.preventDefault();
    handleVerification("sms", smsCode);
  };

  return (
    <div className="container mx-auto flex flex-col items-center">
      <h1 className="text-3xl font-bold text-white mb-10">
        {
          <h1 className="text-3xl font-bold text-white ">
            {emailVerified
              ? `Verify Your Phone Number `
              : `Verify Your Email Address`}
          </h1>
        }
      </h1>

      {emailVerified ? (
        <div className="mb-6">
          <PhoneIcon />{" "}
        </div>
      ) : (
        <div className="mb-6">
          <EmailIcon />
        </div>
      )}

      <p className="text-white mb-6 max-w-[500px] text-center">
        {emailVerified
          ? `We've sent a verification code to your phone via SMS.
           Please enter the code below to verify your phone number
            and continue.`
          : `
        Thank you for signing up! To complete your registration, please check
        your email for a verification code. Enter the code below to verify your
        email address.`}
      </p>
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
        {emailVerified ? (
          <VerificationForm
            label={VERIFICATION_MESSAGES.verifySMS}
            code={smsCode}
            setCode={setSmsCode}
            onSubmit={handleSmsSubmit}
            isButtonDisabled={smsCode.length !== 6}
            buttonText="Verify SMS"
          />
        ) : (
          <VerificationForm
            label={VERIFICATION_MESSAGES.verifyEmail}
            code={emailCode}
            setCode={setEmailCode}
            onSubmit={handleEmailSubmit}
            isButtonDisabled={emailCode.length !== 6}
            buttonText="Verify Email"
          />
        )}
      </div>
    </div>
  );
};

export default VerifyAccount;
