import { Navigate, Outlet } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userState } from "../atoms/atoms";

const ProtectedRoutes = () => {
  const user = useRecoilValue(userState);
  return user?.email ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
