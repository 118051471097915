// This is a test comment to check editing functionality

import React, { useState } from "react";
import IndividualForm from "../forms/IndividualForm";
import BusinessForm from "../forms/BusinessForm";
import { useSetRecoilState } from "recoil";
import { loaderState, userFormState, userState } from "../../atoms/atoms";
import { createBusiness, createIndividual } from "../../services/api/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const FormContainer = () => {
  const [activeTab, setActiveTab] = useState("individual");
  const setLoader = useSetRecoilState(loaderState);
  const setUserData = useSetRecoilState(userState);
  const setUserFormState = useSetRecoilState(userFormState);
  const navigate = useNavigate();
  const handleIndividualSubmit = async (values, { setSubmitting }) => {
    setLoader(true);
    try {
      const response = await createIndividual({
        ...values,
        individual: {
          ...values.individual,
          id_country_code: values?.individual?.residential_country_code,
        },
      });

      if (response?.data?.status === "success") {
        setUserData(response.data);
        setUserFormState("EDDQ - Individual");
        toast.success(response.data.message);

        navigate("/verify");
      } else {
        toast.error(
          response?.response?.data?.message ??
            response?.message ??
            "Something went wrong"
        );
      }
    } catch (error) {
      toast.error("An error occurred: " + error.message);
    } finally {
      setLoader(false);
      setSubmitting(false);
    }
  };

  const handleBusinessSubmit = async (values, { setSubmitting }) => {
    setLoader(true);
    try {
      const response = await createBusiness({
        ...values,
        beneficial_owners: values.beneficial_owners.map((owner) => ({
          ...owner,
          id_country_code: owner.residential_country_code,
        })),
        business: {
          ...values.business,
          registration_number: parseInt(values.business.registration_number),
        },
      });
      if (response?.data?.status === "success") {
        toast.success(response.data.message);
        setUserData(response.data);
        setUserFormState("EDDQ - Business");
        navigate("/verify");
      } else {
        toast.error(
          response?.response?.data?.message ??
            response?.message ??
            "Something went wrong"
        );
      }
    } catch (error) {
      toast.error("An error occurred: " + error.message);
    } finally {
      setLoader(false);
      setSubmitting(false);
    }
  };

  return (
    <div className="bg-gray-800 rounded-lg shadow-lg p-8 max-w-6xl w-full">
      <div className="flex justify-center mb-6">
        <button
          type="button"
          onClick={() => setActiveTab("individual")}
          className={`px-6 py-3 ${
            activeTab === "individual" ? "bg-[#b5924e]" : "bg-gray-700"
          } text-white rounded-l-lg transition-colors text-lg font-medium`}
        >
          Individual
        </button>
        <button
          type="button"
          onClick={() => setActiveTab("business")}
          className={`px-6 py-3 ${
            activeTab === "business" ? "bg-[#b5924e]" : "bg-gray-700"
          } text-white rounded-r-lg transition-colors text-lg font-medium`}
        >
          Business
        </button>
      </div>

      {activeTab === "individual" ? (
        <IndividualForm onSubmit={handleIndividualSubmit} />
      ) : (
        <BusinessForm onSubmit={handleBusinessSubmit} />
      )}
    </div>
  );
};

export default FormContainer;
