import React, { useLayoutEffect, useState } from "react";
import { Formik, Form, Field, useFormikContext } from "formik";
import { useDropzone } from "react-dropzone";
import * as Yup from "yup";
import "tailwindcss/tailwind.css";
import {
  getUserStatus,
  onboardUserBusiness,
  onboardUserIndividual,
} from "../services/api/auth";
import { toast } from "react-toastify";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { loaderState, userState } from "../atoms/atoms";
import { useNavigate } from "react-router-dom";

const formData = require("../constants/EddFields.json").data;

// Filter form sections
const individualFields = formData.filter(
  (field) => field.required_form === "EDDQ - Individual"
);
const businessFields = formData.filter(
  (field) => field.required_form === "EDDQ - Business"
);

// Generate validation schema
const generateValidationSchema = (fields) => {
  const shape = {};
  fields.forEach((field) => {
    if (field.is_required) {
      if (field.response_type === "File-Upload") {
        shape[field.prompt_id] = Yup.string().required(
          "File upload is required"
        );
      } else {
        shape[field.prompt_id] = Yup.string().required(
          "This field is required"
        );
      }
    }
  });
  return Yup.object().shape(shape);
};

const FileUploadField = ({ name }) => {
  const { setFieldValue } = useFormikContext();
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFieldValue(name, acceptedFiles[0]);
    },
  });

  return (
    <div
      {...getRootProps()}
      className="border-2 text-white border-dashed p-4 rounded text-center cursor-pointer"
    >
      <input {...getInputProps()} name={name} />
      <p className="text-white">
        Drag and drop a file here, or click to select a file
      </p>
      {acceptedFiles.length > 0 && (
        <ul className="mt-2">
          {acceptedFiles.map((file, index) => (
            <li key={index} className="text-sm text-green-700">
              {file.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const renderFieldError = (error) => (
  <span className="text-red-500 text-sm">{error}</span>
);

const ClientQuestionnaire = () => {
  const navigate = useNavigate();
  const user = useRecoilValue(userState);
  const setLoader = useSetRecoilState(loaderState);
  const [userStatus, setUserStatus] = useState({});

  useLayoutEffect(() => {
    if (user?.email) {
      getUserStatusDetail();
    }
    //eslint-disable-next-line
  }, []);

  const getUserStatusDetail = async () => {
    try {
      setLoader(true);
      const response = await getUserStatus(user?.email);

      if (response?.data?.status === "success") {
        setUserStatus(response?.data?.userData);
      } else {
        toast.error(response?.response?.data?.message ?? response?.message);
        navigate("/");
      }
    } catch (error) {
      toast.error(error.message || "somethings went wrong");
      navigate("/");
    } finally {
      setLoader(false);
    }
  };

  const validationSchemaBusiness = Yup.object({
    business: generateValidationSchema(businessFields),
  });

  const validationSchemaIndividual = Yup.object({
    individual: generateValidationSchema(individualFields),
  });

  const renderFields = (fields, sectionName, errors) => {
    return fields.map((field) => {
      const fieldName = `${sectionName}.${field.prompt_id}`;

      switch (field.response_type) {
        case "Free-Form":
          return (
            <div key={field.prompt_id} className="mb-4 text-white">
              <label
                htmlFor={field.prompt_id}
                className="block font-medium mb-2 text-white"
              >
                {field.prompt}
              </label>
              <Field
                as="textarea"
                name={fieldName}
                id={field.prompt_id}
                className="w-full border p-2 rounded bg-gray-800"
              />
              {errors[`${sectionName}`]?.[`${field?.prompt_id}`] &&
                renderFieldError(
                  errors[`${sectionName}`]?.[`${field?.prompt_id}`]
                )}
            </div>
          );
        case "Single-Select":
          return (
            <div key={field.prompt_id} className="mb-4">
              <label
                htmlFor={field.prompt_id}
                className="block font-medium mb-2 text-white"
              >
                {field.prompt}
              </label>
              <Field
                as="select"
                name={fieldName}
                id={field.prompt_id}
                className="w-full border p-2 rounded text-white bg-gray-800"
              >
                <option value="">Select an option</option>
                {field.response_options.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </Field>
              {errors[`${sectionName}`]?.[`${field?.prompt_id}`] &&
                renderFieldError(
                  errors[`${sectionName}`]?.[`${field?.prompt_id}`]
                )}
            </div>
          );
        case "File-Upload":
          return (
            <div key={field.prompt_id} className="mb-4">
              <label
                htmlFor={field.prompt_id}
                className="block font-medium mb-2 text-white"
              >
                {field.prompt}
              </label>
              <FileUploadField name={fieldName} />
              {errors[`${sectionName}`]?.[`${field?.prompt_id}`] &&
                renderFieldError(
                  errors[`${sectionName}`]?.[`${field?.prompt_id}`]
                )}
            </div>
          );
        default:
          return null;
      }
    });
  };

  // Utility function for JSON transformation
  const transformToPromptsIndividual = (input) => {
    const prompts = {};
    for (const [key, value] of Object.entries(input.individual)) {
      if (typeof value === "string") {
        prompts[key] = { response: value };
      } else if (typeof value === "object" && value.path) {
        prompts[key] = { response: value };
      }
    }
    return { prompts };
  };

  const transformToPromptsBusiness = (data) => {
    const prompts = {};
    for (const [key, value] of Object.entries(data.business)) {
      if (typeof value === "string") {
        prompts[key] = { response: value };
      } else if (typeof value === "object" && value.path) {
        prompts[key] = { response: value };
      }
    }
    return { prompts };
  };

  return (
    <Formik
      initialValues={
        userStatus?.required_form === "EDDQ - Individual"
          ? {
              individual: Object.fromEntries(
                individualFields.map((field) => [field.prompt_id, ""])
              ),
              business: {}, // Empty object for business when "EDDQ - Individual"
            }
          : {
              individual: {}, // Empty object for individual when "EDDQ - Business"
              business: Object.fromEntries(
                businessFields.map((field) => [field.prompt_id, ""])
              ),
            }
      }
      validationSchema={
        userStatus?.required_form === "EDDQ - Individual"
          ? validationSchemaIndividual
          : validationSchemaBusiness
      }
      onSubmit={async (values) => {
        if (userStatus?.required_form === "EDDQ - Individual") {
          setLoader(true);
          const transformedData = transformToPromptsIndividual(values);
          try {
            const response = await onboardUserIndividual(
              transformedData,
              user?.email
            );
            if (response?.data?.status === "success") {
              toast.success(response.data.message);
              navigate("/congratulation");
            } else {
              toast.error(
                response?.response?.data?.message ?? response?.message
              );
            }
          } catch (error) {
            toast.error(error.message || "Something went wrong");
          } finally {
            setLoader(false);
          }
        } else {
          setLoader(true);
          const transformedData = transformToPromptsBusiness(values);
          try {
            const response = await onboardUserBusiness(
              transformedData,
              user?.email
            );
            if (response?.data?.status === "success") {
              toast.success(response.data.message);
              navigate("/congratulation");
            } else {
              toast.error(
                response?.response?.data?.message ?? response?.message
              );
            }
          } catch (error) {
            toast.error(error.message || "Something went wrong");
          } finally {
            setLoader(false);
          }
        }
      }}
    >
      {({ errors }) => (
        <Form className="p-6 max-w-3xl mx-auto bg-gray-800">
          {userStatus?.status === "REQUESTED" &&
            userStatus?.required_form === "EDDQ - Individual" && (
              <>
                <h1 className="text-2xl font-bold mb-6 text-white">
                  EDDQ - Individual
                </h1>
                {renderFields(individualFields, "individual", errors)}
              </>
            )}
          {userStatus?.status === "REQUESTED" &&
            userStatus?.required_form === "EDDQ - Business" && (
              <>
                <h1 className="text-2xl font-bold mt-8 mb-6 text-white">
                  EDDQ - Business
                </h1>

                {renderFields(businessFields, "business", errors)}
              </>
            )}
          {userStatus?.status === "REQUESTED" && (
            <div className="mt-4">
              <button
                type="submit"
                className="w-full bg-[#b5924e]  text-white py-2 hover:bg-yellow-600 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Submit
              </button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ClientQuestionnaire;
