const EmailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5} // Corrected for JSX
      stroke="currentColor"
      className="size-20 text-[#b5924e]" // Tailwind-friendly class
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21.75 8.25v9a2.25 2.25 0 01-2.25 2.25H4.5a2.25 2.25 0 01-2.25-2.25v-9m19.5 0L12 13.5 2.25 8.25M21.75 8.25L12 3.75 2.25 8.25"
      />
    </svg>
  );
};

export default EmailIcon;
