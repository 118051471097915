import React from "react";
import { Link } from "react-router-dom";

const Congratulation = () => {
  return (
    <>
      <div className="container mx-auto flex flex-col items-center ">
        <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-xl">
          <div>
            <h1 className="text-3xl font-bold text-green-500 mb-8 ">
              Thank you for completing the onboarding process!
            </h1>
            <p className="congratulations-message text-white">
              We are now reviewing the information you provided as part of our
              due diligence process, which typically takes 2–3 business days.
            </p>
            <p className="congratulations-message text-white mt-3">
              If you have any questions or need assistance in the meantime,
              don't hesitate to reach out to us at{" "}
              <a
                className="text-yellow-600 underline"
                href="mailto:info@sovera.com"
              >
                info@sovera.com
              </a>
              . Our team is here to help!
            </p>
            <p className="congratulations-message text-white mt-3">
              We appreciate your trust in Sovera and look forward to serving
              you!
            </p>

            <Link
              to="/"
              className="mt-4 inline-block  text-center  rounded-full bg-yellow-600 text-white font-bold py-2 px-4  hover:bg-green-700 transition duration-300"
            >
              Go to Home
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Congratulation;
