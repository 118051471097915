import React, { useLayoutEffect } from "react";
import FormContainer from "../components/layout/FormContainer";
import { userFormState, userState } from "../atoms/atoms";
import { useSetRecoilState } from "recoil";

const Signup = () => {
  const setUserData = useSetRecoilState(userState);
  const setUserFormState = useSetRecoilState(userFormState);
  useLayoutEffect(() => {
    setUserData({});
    setUserFormState("");
    //eslint-disable-next-line
  }, []);
  return (
    <div className="container mx-auto flex flex-col items-center">
      <h1 className="text-3xl font-bold text-white mb-8">
        The Smart Treasury Client Registration
      </h1>
      <FormContainer />
    </div>
  );
};

export default Signup;
