import React, { useLayoutEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Signup from "./pages/Signup";
import Sign from "./pages/Sign";
import VerifyAccount from "./pages/VerifyAccount";
import Loader from "./helpers/Loader";
import { useRecoilState } from "recoil";
import { loaderState } from "./atoms/atoms";
import ClientQuestionnaire from "./pages/ClientQuestionnaire";
import Congratulation from "./pages/Congratulation";
import Header from "./components/common/Header";
import ProtectedRoutes from "./helpers/ProtectedRoutes";

const App = () => {
  const [, setLoader] = useRecoilState(loaderState);

  useLayoutEffect(() => {
    setLoader(false);
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Loader />
      <ToastContainer autoClose={7000} transition={Zoom} />
      <Router>
        <main>
          <Header />
          <div className="min-h-[calc(100vh-120px)]  flex items-center bg-gradient-to-b from-gray-900 to-black py-8 px-4 font-roboto">
            <div className="container mx-auto flex flex-col items-center">
              <Routes>
                <Route path="/" element={<Signup />} />
                <Route path="/login" element={<Sign />} />
                <Route element={<ProtectedRoutes />}>
                  <Route path="/verify" element={<VerifyAccount />} />
                  <Route
                    path="/questionnaire"
                    element={<ClientQuestionnaire />}
                  />
                  <Route path="/congratulation" element={<Congratulation />} />{" "}
                </Route>
              </Routes>
            </div>
          </div>
        </main>
      </Router>
    </>
  );
};

export default App;
